<div class="flex flex-wrap">
  <app-top-bar class="w-full"></app-top-bar>
  <p-toast></p-toast>

  <div
    class="side-menu"
    [ngClass]="{ 'only-icon': !layoutService.state.sideMenuOpen }"
  >
    <p-tree class="w-full" [value]="menuItems" [selection]="false">
      <ng-template let-node pTemplate="url">
        <div
          class="cursor-pointer flex justify-content-start align-items-center gap-3"
          [ngClass]="{
            'justify-content-start': layoutService.state.sideMenuOpen,
            'my-1': layoutService.state.sideMenuOpen,

            'justify-content-center': !layoutService.state.sideMenuOpen,
            'my-2': !layoutService.state.sideMenuOpen
          }"
          tooltipPosition="right"
          [pTooltip]="node.label"
          [tooltipDisabled]="layoutService.state.sideMenuOpen"
          (click)="navigateTo(node.route)"
        >
          <fa-icon [icon]="node?.menuIcon" class="menu-icon"></fa-icon>

          <h4
            *ngIf="node.leaf && layoutService.state.sideMenuOpen"
            class="my-1"
          >
            {{ node.label }}
          </h4>

          <h3
            *ngIf="layoutService.state.sideMenuOpen && !node.leaf"
            class="my-1 text-left"
          >
            {{ node.label }}
          </h3>
        </div>
      </ng-template>
      <ng-template let-node pTemplate="default">
        <div
          class="cursor-pointer flex justify-content-start align-items-center gap-3"
          [ngClass]="{
            'justify-content-start': layoutService.state.sideMenuOpen,
            'my-1': layoutService.state.sideMenuOpen,

            'justify-content-center': !layoutService.state.sideMenuOpen,
            'my-2': !layoutService.state.sideMenuOpen
          }"
          tooltipPosition="right"
          [pTooltip]="node.label"
          [tooltipDisabled]="layoutService.state.sideMenuOpen"
          (click)="openMenu(node)"
        >
          <fa-icon [icon]="node?.menuIcon" class="menu-icon"></fa-icon>

          <h4
            *ngIf="node.leaf && layoutService.state.sideMenuOpen"
            class="my-1"
          >
            {{ node.label }}
          </h4>

          <h3
            *ngIf="layoutService.state.sideMenuOpen && !node.leaf"
            class="my-1"
          >
            {{ node.label }}
          </h3>
        </div>
      </ng-template>
    </p-tree>
  </div>

  <div class="container p-4 flex justify-content-center">
    <router-outlet></router-outlet>
  </div>
</div>

import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { ButtonModule } from 'primeng/button';
import { StorageService } from '../../services/utils/storage.service';
import { RootRoutes } from 'src/app/app-routing.module';

@Component({
  standalone: true,
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  imports: [TranslocoModule, ButtonModule],
  styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent {
  public errorCode: string | undefined;
  public errorMessage: string | undefined;
  public errorDescription: string | undefined;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _localStorageService: StorageService
  ) {
    this._route.data.subscribe((data) => {
      this.errorCode = data?.['errorCode'];
      this.errorMessage = data?.['errorMessage'];
      this.errorDescription = data?.['errorDescription'];
    });
  }

  public goToLogin() {
    this._localStorageService.clear();
    this._router.navigate(['/']);
  }
}

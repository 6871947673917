import { Injectable } from '@angular/core';



@Injectable({
  providedIn: 'root',
})
export class StorageService {


  constructor() {

  }



  public get(key: string): any | null {
    const data = localStorage.getItem(key);

    if (!data) {
      return null;
    }

    return JSON.parse(localStorage.getItem(key)!);
  }

  public set(key: string, value: any) {
    if (value === null || value === undefined) {
      localStorage.removeItem(key);
      return;
    }

    localStorage.setItem(key, JSON.stringify(value));
  }

  private _getLocalSavedFilterKey(topic: string, suffix: string = '_v2') {
    return `search-payload-${topic.toLowerCase()}${suffix ?? ''}`;
  }

  public clear() {
    localStorage.clear();
  }
}

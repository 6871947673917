import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, switchMap, throwError } from 'rxjs';
import { RootRoutes } from 'src/app/app-routing.module';
import { LoggedUserInfo } from 'src/app/shared/models/auth';
import { RegistryService } from 'src/app/shared/services/api/registry/registry.service';
import { AuthService } from '../../shared/services/api/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private _authService: AuthService,
    private _registryService: RegistryService,
    private _router: Router
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const idToken = this._authService.token;

    let cloned = req.clone({
      headers: idToken
        ? req.headers.set('Authorization', 'Bearer ' + idToken)
        : req.headers,
    });

    return next.handle(cloned).pipe(
      catchError((error: HttpErrorResponse) => {
        let authData = this._authService.userInfo;
        const currentTime = Date.now(); // Current time in milliseconds

        if (error.statusText == 'Unknown Error') {
          if (
            this._authService.userInfo &&
            currentTime > this._authService.userInfo.expirationInUnixTime
          ) {
            return this.manageRefreshToken(
              cloned,
              next,
              this._authService.userInfo
            );
          }
        } else if (error.status === 401) {
          if (
            // error.headers.get('TOKEN-EXPIRED') !== null &&
            error.status === 401 &&
            authData &&
            !!authData.refreshToken
          ) {
            return this.manageRefreshToken(cloned, next, authData);
          } else {
            this._authService.logout();
          }
        }
        return throwError(() => error);
      })
    );
  }

  public manageRefreshToken(
    request: HttpRequest<any>,
    next: HttpHandler,
    loginData: LoggedUserInfo
  ): Observable<HttpEvent<any>> {
    return this._authService
      .refreshToken(loginData.userId, loginData.refreshToken)
      .pipe(
        switchMap((res: LoggedUserInfo) => {
          if (!!res && res.token) {
            this._authService.token = res.token;
            const accountInfo = this._authService.userInfo.accountInfo;
            this._authService.userInfo = {
              ...res,
              accountInfo: accountInfo,
            };
            debugger;
            return this._registryService
              .getMyAccountToken(this._authService.userInfo.accountInfo!.id)
              .pipe(
                switchMap((accountRes) => {
                  request = request.clone({
                    setHeaders: {
                      Authorization: `Bearer ${accountRes.token}`,
                    },
                  });

                  return next.handle(request);
                })
              );
          } else {
            return throwError(() => new HttpErrorResponse({ status: 403 }));
          }
        }),
        catchError((error) => {
          if (error.status === 403) {
            this._authService.logout();
            this._router.navigate([RootRoutes.LOGIN]);
          }
          return throwError(() => error);
        })
      );
  }
}

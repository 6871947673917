import { Environment } from 'src/app/core/models/environment';

export const environment: Environment = {
  production: false,
  apiUrl: 'https://dev.ehiapp.it/api',

  apiPublicKey:
    '-----BEGIN PUBLIC KEY-----\n' +
    'MIGeMA0GCSqGSIb3DQEBAQUAA4GMADCBiAKBgG3pwy2Vcv9MMoQSt1tN54GFhB7Y\n' +
    'OiDvU9vH9IuExC+5ixM8RbODnTlKiUCBY1CfRBEcy/7gIpV9P9/7fVl9heksVAC1\n' +
    '0dARxtXX2bOjagXHO2RMJNChw/h3G9leuM/VxHAf9zl4rhpoeBZXsyLsQdZpEFQx\n' +
    'IBeGsCiwa9YyyfX7AgMBAAE=\n' +
    '-----END PUBLIC KEY-----',
};

<div class="h-screen flex justify-content-center">
  <div class="flex flex-column h-full text-center justify-content-center">
    <h1 class="error-code">
      {{ errorCode }}
    </h1>

    <h2 class="error-message">
      {{ errorMessage | transloco }}
    </h2>
    <h3 class="error-desc font-normal">
      {{ errorDescription | transloco }}
    </h3>
    <div class="w-full flex justify-content-center mt-6">
      <p-button
        class="w-1/2"
        [label]="'common.goToHome' | transloco"
        [ariaLabel]="'common.goToHome' | transloco"
        (click)="goToLogin()"
      >
      </p-button>
    </div>
  </div>
</div>

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/guards/auth.guard';
import { ErrorPageComponent } from './shared/components/error-pages/error-page.component';
export enum RootRoutes {
  LOGIN = 'login',
  APP = 'app',
  CONFIRMOTP = 'confirmation-required',
  DASHBOARD = 'dashboard',
  EVENT = 'events',
  GIVEAWAY = 'giveaways',
  ARTISTS = 'artists',
  SETTINGS = 'settings',
  MANAGE = 'manage',
  TAGS = 'tags',
  LIST = 'list',
  RESETPASSWORD = 'reset-password',
  COMPANY = 'company',
  NEW = 'new',
  EDIT = 'edit',
  DETAIL = 'detail',
  PROFILESELECTION = 'profile-selection',
  ERROR_404 = '404',
  MY_PROFILE = 'my-profile',
}

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./public-portal/public-portal.module').then(
        (m) => m.PublicPortalModule
      ),
    title: 'Ehi',
  },
  {
    path: RootRoutes.APP,
    title: 'Gestionale Ehi App',
    children: [
      {
        path: RootRoutes.LOGIN,
        loadChildren: () =>
          import('./auth/auth.module').then((m) => m.AuthModule),
      },
      {
        canActivate: [AuthGuard.canActivate],
        path: RootRoutes.DASHBOARD,
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        canActivate: [AuthGuard.canActivate],
        path: RootRoutes.EVENT,
        loadChildren: () =>
          import('./events/events.module').then((m) => m.EventsModule),
      },
      {
        canActivate: [AuthGuard.canActivate],
        path: RootRoutes.ARTISTS,
        loadChildren: () =>
          import('./artists/artists.module').then((m) => m.ArtistsModule),
      },
      {
        canActivate: [AuthGuard.canActivate],

        path: RootRoutes.COMPANY,
        loadChildren: () =>
          import('./company/company.module').then((m) => m.CompanyModule),
      },
      {
        canActivate: [AuthGuard.canActivate],
        path: RootRoutes.SETTINGS,
        loadChildren: () =>
          import('./settings/settings.module').then((m) => m.SettingsModule),
      },
      {
        path: '**',
        redirectTo: RootRoutes.EVENT,
      },
    ],
  },

  {
    path: '**',
    component: ErrorPageComponent,
    data: {
      errorCode: '404',
      errorMessage: 'common.errorCode.404',
      errorDescription: 'common.errorCode.404desc',
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
